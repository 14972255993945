/*PageApp Calendar*/
.react-calendar__navigation{
	color:#FFF;
	line-height:60px!important;
	height:60px!important;
}

.react-calendar__navigation__next2-button{display:none;}
.react-calendar__navigation__prev2-button{display:none;}

.react-calendar{
	width:auto!important;
}

.react-calendar__month-view__weekdays{
	text-align:center;
	font-size:14px;
	font-weight:700;
	padding:5px 0px;
	text-transform: uppercase;
}
.react-calendar__navigation__label__labelText--from{
	font-weight:700;
	text-transform:uppercase;
	font-size:16px;
}
.react-calendar__month-view__weekdays *{
	line-height:28px;
	text-decoration:none!important;
	color:rgba(255,255,255,0.8);
	font-size:13px;
}

.react-calendar__month-view__days__day--weekend,
.react-calendar__month-view__days__day{
	color:#1f1f1f!important;
	font-weight:500;
	font-family:'Roboto', sans-serif;
}

.react-calendar__month-view__days__day--neighboringMonth abbr{
	opacity:0.3;
}

.react-calendar__tile{
	line-height:45px!important;
	border-right:solid 1px rgba(0,0,0,0.05)!important;
	border-bottom:solid 1px rgba(0,0,0,0.05)!important;
}

.react-calendar__tile--now{
	color:#FFF!important;
	position:relative;
	background-color:transparent!important;
}
.react-calendar__tile--now:not(.react-calendar__year-view__months__month):not(.react-calendar__decade-view__years__year)::after{
	content:'';
	width:25px;
	height:25px;
	border-radius:3px;
	position:absolute;
	background-color:#cacaca;
	left:50%;
	margin-left:-12.5px;
	z-index:-1;
	top:50%;
	margin-top:-13px;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__year-view__months__month{
	color: #1f1f1f!important;
	font-weight:700;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__decade-view__years__year,
.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month{
	background-color:rgba(0,0,0,0.1)!important;
	color:#1f1f1f!important;
	font-weight:700;
}

.react-calendar__tile--active{
	color:#FFF!important;
}

.react-calendar *:hover{
	background-color:transparent!important;
}

.theme-dark .react-calendar__month-view__days__day--weekend,
.theme-dark .react-calendar__month-view__days__day{
	color:#FFF!important;
}

.react-calendar__navigation__next-button{
	width:50px;
	height:50px;
	line-height:50px;
	float:right;
	margin-top:5px;
}
.react-calendar__navigation__prev-button{
	width:50px;
	height:50px;
	line-height:50px;
}

.react-calendar__navigation__label__labelText{
	width:70%;
	display:block;
	text-align:center;
	position:absolute;
	top:0px;
	left:50%;
	margin-left:-35%;
}