.particle {
  left: -30px;
  right: -30px;
  top: -350px;
  bottom: -350px;
  height: 200%;
  z-index: 99999;
  pointer-events: none;
  position: fixed;
}

.particle span {
  display: inline-block;
  animation: particles 3s linear 2s 20;
  position: relative;
}
.particle span:after,
.particle span:before {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  transform: rotate(120deg);
}
.particle u:before {
  transform: rotate(240deg);
}

/*main animation*/
@keyframes particles {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(0deg) scale(0.6);
  }
  100% {
    -webkit-transform: translate3d(15px, 1200px, 0px) rotate(180deg) scale(0.6);
  }
}

/*color of particles*/
.particle i.fa-snowflake {
  color: rgba(160, 206, 203, 0.7);
}
.particle i.fa-heart {
  color: rgba(241, 40, 69, 0.7);
}
/*setting the interval for particle groups*/
.particle span:nth-child(3n + 0) {
  animation-duration: 10s;
  animation-iteration-count: 100;
}
.particle span:nth-child(3n + 1) {
  animation-duration: 13s;
  animation-iteration-count: 100;
}
.particle span:nth-child(3n + 2) {
  animation-duration: 15s;
  animation-iteration-count: 100;
}

/* different delays so they don't all start at the same time */
.particle span:nth-child(7n + 0) {
  opacity: 0.1;
  animation-delay: 0s;
}
.particle span:nth-child(7n + 1) {
  opacity: 0.2;
  animation-delay: 2s;
}
.particle span:nth-child(7n + 2) {
  opacity: 0.3;
  animation-delay: 4s;
}
.particle span:nth-child(7n + 3) {
  opacity: 0.4;
  animation-delay: 6s;
}
.particle span:nth-child(7n + 4) {
  opacity: 0.5;
  animation-delay: 8s;
}
.particle span:nth-child(7n + 5) {
  opacity: 0.6;
  animation-delay: 10s;
}
.particle span:nth-child(7n + 6) {
  opacity: 0.7;
  animation-delay: 12s;
}
