/*Accordion Settings*/
.accordion {
	* {
		outline: 0 !important;
		box-shadow: 0px !important;
		box-shadow: none !important;
	}
	.btn {
		&:first-child {
			width: 100%;
		}
	}
}
.accordion-button{background-color:transparent;}
.btn.accordion-btn {
	text-align: left !important;
	padding: 15px 17px;
	font-size: 13px;
	font-weight: 700;
}
.accordion-icon {
	position:absolute;
	width:50px;
	height:50px;
	line-height:50px!important;
	right:0px;
	transition: all 250ms ease;
	top:50%;
	top:0px!important;
	text-align:center;
}

.accordion-custom .accordion-item{border-bottom:0px; background-color:transparent;}
.accordion-custom .accordion-btn::after{
	display:none;
}
.accordion-custom .accordion-button:not(.collapsed){
	background-color:transparent;
	color:inherit;
}

.accordion-custom .accordion-button[aria-expanded="true"] .accordion-icon{
	transform:rotate(45deg);
}