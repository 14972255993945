/*Typography Settings*/
body {
	font-size: $font-main-size;
	font-family: $font-main-family !important;
	line-height: $font-main-line;
	color: $font-main-color !important;
    background-color: $theme-light-structure;
}

h1, h2, h3, h4, h5, h6{
    font-weight:$font-heading-weight;
	color: $font-heading-color;
	font-family: $font-heading-family !important;
    margin-bottom:5px;
	letter-spacing: -0.3px;
}

h1{font-size:$font-h1-size; line-height:$font-h1-line}
h2{font-size:$font-h2-size; line-height:$font-h2-line}
h3{font-size:$font-h3-size; line-height:$font-h3-line}
h4{font-size:$font-h4-size; line-height:$font-h4-line}
h5{font-size:$font-h5-size; line-height:$font-h5-line}

a {text-decoration: none !important;}
p {
	color: $font-main-color;
	margin-bottom: $globalMargin;
    padding-bottom: 0px
}
